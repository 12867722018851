<template>
  <div class="login-container">
      <div class="login-box" v-loading="loginLoading" v-if="!userInfo.isLogin">
        <div class="login-tags">
<!--          <div :class="tags ? 'title' : 'title col'" @click="tagSwitch">扫码登录</div>-->
<!--          <div class="c"></div>-->
          <div :class="!tags ? 'title' : 'title col'">验证码登录</div>
        </div>
        <div class="login-cont">
<!--          <div class="login-item" v-if="!tags">-->
<!--            <div class="login-item-title">微信扫码,立即登录</div>-->
<!--            <div class="login-img">-->
<!--              <div v-if="loginDetail">-->
<!--                <img :src="loginDetail.url" alt="">-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
          <div class="login-item"> <!--v-else-->
            <div class="login-form">
              <el-form :model="form" :rules="rules" ref="form">
                <el-form-item class="list-item" prop="phone">
                  <el-input v-model="form.phone" maxlength="11" placeholder="请输入手机号"></el-input>
                </el-form-item>
                <el-form-item class="list-item" prop="smsCode">
                  <el-input v-model="form.smsCode" maxlength="6" placeholder="请输入验证码"></el-input>
                  <el-button class="text-btn" type="text" :disabled="isCountDown" @click="sendCode">{{ countDownText }}</el-button>
                </el-form-item>
                <el-form-item class="list-item">
                  <el-button type="primary" class="login-btn" @click="submitForm">登 录</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
        <div class="login-foot">登录体验完整功能</div>
      </div>
      <div class="login-box" v-else>
        <div class="user-welcome">
          <div class="user-title">欢迎回来!</div>
          <div class="user-item">
            <img :src="require(`@/assets/images/avatar/${userSetInfo.headImgUrl}`)" alt="" v-if="userSetInfo">
            <span>{{ userSetInfo.nickname }}</span>
          </div>
          <div class="user-btn" @click="toPath">个人中心 GO</div>
        </div>
      </div>
  </div>
</template>
<script>
import PromotionApi from '@/api/promotion'
import UserPromotApi from "@/api/user"
import { EventBus } from "@/utils/eventBus"

export default {
  name: 'Login',
  data() {
    return {
      loginDetail: '',
      tags: true,
      loginLoading: false,
      shake: false,
      form: {
        phone: '',
        smsCode: ''
      },
      rules: {
        phone: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          { type: 'string',pattern: /^1[3-9]\d{9}$/, message: '请输入正确手机号', trigger: 'blur' }
        ],
        smsCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { len: 6, message: '验证码长度为6位', trigger: 'blur' }
        ]
      },
      countDown: 0,
      timerCode: null,
      windowWidth: document.body.clientWidth,
      isMobile: false,
      userInfo: {
        isLogin: false, // 用户登录状态
        username: '',
        nickname: '',
        head: '',
        token: ''
      },
      userSetInfo: ''
    }
  },
  created() {
    this.isMobile = this.windowWidth < 901;
    this.getUser()
  },
  watch: {
    // 监听页面宽度
    windowWidth(newWidth) {
      this.isMobile = newWidth < 901;
    }
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.windowWidth = document.documentElement.clientWidth
      })()
    }
    EventBus.$on('userLogin', (msg) => {
      if(msg === 'userLoginTrue'){
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
        this.userSetInfo = JSON.parse(localStorage.getItem('userSetInfo'))
      }
    })
    EventBus.$on('userSetInfo', (msg) => {
      if(msg === 'userInfoTrue'){
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
        this.userSetInfo = JSON.parse(localStorage.getItem('userSetInfo'))
      }
    })
    this.getUser()
  },
  beforeDestroy() {
    clearInterval(this.timerLogin)
  },
  computed: {
    isCountDown() {
      return this.countDown > 0;
    },
    countDownText() {
      // console.log(this.isCountDown)
      return this.isCountDown ? `${this.countDown}s后重新发送` : '发送验证码';
    }
  },
  methods: {
    tagSwitch(){
      this.tags = !this.tags
      if(this.tags === false){
        // 二维码接口
        this.loginLoading = true
        this.getLoginUrl()
      }else {
        clearInterval(this.timerLogin)
      }
    },
    // openLogin(){
    //   if(this.shake === true){
    //     return
    //   }
    //   this.shake = true
    //   if(this.isMobile){
    //     // this.loginDialogVisible = true
    //   }else {
    //     this.getLoginUrl()
    //   }
    // },
    // closedialogVisible(){
    //   //关闭弹窗 并 清除定时器查询
    //   clearInterval(this.timerLogin)
    //   this.shake = false
    //   // this.loginDialogVisible = false
    // },
    // 获取登录二维码
    getLoginUrl(){
      let _this = this;
      PromotionApi.getLoginUrl().then(res => {
        // key 、 url
        _this.loginDetail = res.data
        _this.getLoginStatus(_this.loginDetail.key)
        this.loginLoading = false
      }).catch(() => {
      })
    },
    // 查询是否登录
    queryLogin(key){
      let _this = this;
      PromotionApi.queryLogin(key).then(res => {
        if(res.data.isLogin === true){
          _this.$message.success('登录成功')
          localStorage.setItem('userInfo', JSON.stringify(res.data))
          _this.getUserDetailVo(res.data.token)
        }
      }).catch(() => {
      })
    },
    //定时器查询登录状态
    getLoginStatus(key){
      this.timerLogin = setInterval(() => {
        this.queryLogin(key)
      }, 3000);
    },
    // 登录成功获取用户信息
    getUserDetailVo(token){
      let _this = this
      UserPromotApi.getUserDetailVo(token).then(res => {
        localStorage.setItem('userSetInfo', JSON.stringify(res.data))
        // _this.$emit('userInfoSelf','userTrue')
        // 传递登录成功的消息
        EventBus.$emit("userLogin", 'userLoginTrue')
        _this.getUser()
        // 清除定时器
        clearInterval(_this.timerLogin)
      }).catch(() => {
      })
    },
    // 发送验证码的请求
    sendCode() {
      let _this = this
      this.$refs.form.validateField('phone', (valid) => {
        if (!valid) {
          // 发送验证码的请求
          if (!_this.isCountDown){
            _this.postSms(this.form.phone)
          }
        } else {
          _this.$message.error('手机号不正确')
          return false;
        }
      })
    },
    // 验证码倒计时 定时器
    startCountDown() {
      if (this.timerCode) return;
      this.countDown = 60;
      this.timerCode = setInterval(() => {
        if (this.countDown > 0) {
          this.countDown -= 1;
        } else {
          clearInterval(this.timerCode);
          this.timerCode = null;
        }
      }, 1000);
    },
    // 登录提交
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          // 验证码输入正确，执行提交操作
          // console.log('验证码输入正确，提交表单');
          this.postLoginByPhone()
        } else {
          // console.log('表单验证失败');
          return false;
        }
      });
    },
    // 登录
    postLoginByPhone(){
      let _this = this
      let time = new Date().getTime()
      let key = localStorage.getItem('key')
      let ticket = localStorage.getItem('ticket')
      let setOf = ticket + time + ticket + ticket + time + ticket
      let infoMd5 = this.$md5(setOf)
      UserPromotApi.postLoginByPhone(_this.form,key,time,infoMd5).then(res => {
        if(res.data.isLogin === true){
          _this.$message.success('登录成功')
          localStorage.setItem('userInfo', JSON.stringify(res.data))
          _this.getUserDetailVo(res.data.token)
          setTimeout(()=>{
            clearInterval(_this.timerCode);
            _this.timerCode = null;
            _this.countDown = 0;
          },200)
        }
      }).catch(() => {
      })
    },
    // 获取验证码
    postSms(phone){
      let _this = this
      let time = new Date().getTime()
      let key = localStorage.getItem('key')
      let ticket = localStorage.getItem('ticket')
      let setOf = ticket + time + ticket + ticket + time + ticket
      let infoMd5 = this.$md5(setOf)
      UserPromotApi.postSms(phone,key,time,infoMd5).then(res => {
        _this.$message.success('验证码已发送')
        // 开启验证码定时器
        _this.startCountDown();
      }).catch(() => {
      })
    },
    // 更新用户信息
    getUser(){
      if(localStorage.getItem('userInfo')){
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
      }
      if(localStorage.getItem('userSetInfo')){
        this.userSetInfo = JSON.parse(localStorage.getItem('userSetInfo'))
      }
    },
    toPath(){
      localStorage.setItem('currentIndex',131)
      this.$router.push('/user_promot/setUp')
    }
  }
}
</script>
<style scoped lang="less">
.login-container{
  width: 360px;
  height: 430px;
  box-shadow: 10px 10px 1px 0 #e3f1ff;
  border-radius: 16px;
  .login-box{
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url("../../../../assets/images/home/new-home-login-bg.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: #ffffff;
    box-shadow: 0 0 15px rgba(131,151,255,0.25);
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .login-tags{
      width: 80%;
      height: 66px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-evenly;
      .c{
        width: 1px;
        height: 16px;
        background-color: #d7d7d7;
      }
      .title{
        font-size: 17px;
        font-weight: bold;
        color: #4a4a4a;
        cursor: pointer;
      }
      .col{
        color: #498fdd;
      }
      .title:hover{
        color: #498fdd;
      }
    }
    .login-cont{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .login-item{
        position: relative;
        padding-top: 54px;
        .login-item-title{
          position: relative;
          text-align: center;
          font-size: 16px;
          color: #202020;
        }
        .login-img{
          position: relative;
          border-radius: 15px;
          border: none;
          margin-top: 30px;
          >div{
            padding: 10px;
            border: 1px solid #e6e6e6;
            img{
              width: 124px;
              height: 124px;
              padding: 0;
              margin: 0;
              border: 0;
              background-color: #ffffff;
            }
          }
          >div:before,>div:after{
            content: '';
            position: absolute;
            width: 10px;
            height: 10px;
          }
          >div:before{
            bottom: 0;
            left: 0;
            border-bottom: 2px solid #36b7e6;
            border-left: 2px solid #36b7e6;
          }
          >div:after{
            bottom: 0;
            right: 0;
            border-bottom: 2px solid #36b7e6;
            border-right: 2px solid #36b7e6;
          }
        }
        .login-img:before,.login-img:after {
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
        }
        .login-img:before {
          top: 0;
          left: 0;
          border-top: 2px solid #36b7e6;
          border-left: 2px solid #36b7e6;
        }
        .login-img:after {
          top: 0;
          right: 0;
          border-top: 2px solid #36b7e6;
          border-right: 2px solid #36b7e6;
        }

        .login-form{
          width: 240px;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          /deep/ .el-form{
            width: 100%;
            .list-item{
              position: relative;
              margin-bottom: 30px;
              .el-form-item__label{
                font-size: 12px !important;
              }
              .el-input__inner{
                font-size: 12px !important;
                border: 0 !important;
                border-radius: 10px !important;
                height: 44px !important;
                box-shadow: 0 0 15px rgba(131,151,255,0.25);
              }
              .el-form-item__error{
                margin-top: 5px;
              }
              .text-btn{
                position: absolute;
                top: 40px;
                right: 10px;
                z-index: 9;
                span{
                  font-size: 12px !important;
                }
              }
              .login-btn{
                width: 100%;
                height: 46px;
                border-radius: 10px;
                margin-top: 24px;
              }
            }
            .list-item:nth-child(2){
              margin-bottom: 30px;
            }
          }
        }
      }
    }
    .login-foot{
      position: absolute;
      bottom: 24px;
      width: 100%;
      text-align: center;
      font-size: 14px;
      color: #707070;
    }

    .user-welcome{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .user-title{
        height: 80px;
        line-height: 80px;
        font-size: 16px;
        color: #202020;
        text-align: center;
      }
      .user-item{
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        span{
          max-width: 260px;
          margin: 20px auto 0 auto;
          font-size: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        img{
          width: 80px;
          height: 80px;
          border-radius: 80px;
          border: 0;
          margin: 0;
        }
      }
      .user-btn{
        width: 160px;
        height: 40px;
        line-height: 40px;
        margin: 30px auto 0 auto;
        border-radius: 40px;
        text-align: center;
        background-color: #ffffff;
        box-shadow: 0 0 15px rgba(131,151,255,0.25);
        cursor: pointer;
      }
    }
  }
}
</style>
